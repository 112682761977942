

/* About Section */

.about-container {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  top: 0;
  bottom: 0;
  padding: 7rem 0 10rem 0;
}

.hi {
  font-size: 35px;
  /* color: #6e6d04; */
}

.about-paragraph {
  text-align:left;
  /* display: flex; */
  font-size: 25px;
  line-height: 35px;
  width: 65%;
}

.highlight {

}

.me-div {
  margin-left: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.me-div img {
  height: 350px;
  width: 350px;
  object-fit: cover;
  border-radius: 50%;
}

.about-paragraph span {
  color: rgba(255, 217, 0, 0.801);
  font-weight: 1000;
}





@media screen and (max-width: 1100px) {
  .about-container{
    width: 100%;
    flex-direction: column-reverse;
    margin: 2rem 0 3rem 0;
  }

  .me-div {
    width: 80%;
    /* height: 600px; */
    margin: 0 0 45px 0;
  }

  .me-div img {
    /* height: 350px; */
  }
}

@media screen and (max-width: 800px) {
  .me-div img {
    height: 250px;
    width: 250px;
  }
}

@media screen and (max-width: 600px) {
  .about-container{
    width: 100%;
    flex-direction: column-reverse;
    margin: 2rem 0 3rem 0;
  }

  /* .me-div {
    width: 100%;;
    margin: 0;
  } */
  /* .me-div img {
    width: 100%;
    height: 500px;
  } */

  .about-paragraph {
    width: 70%;
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .about-paragraph {
    width: 85%;
    font-size: 20px;
  }
}
