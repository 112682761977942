.graph-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 2rem -1rem;
}

.graph-title {
  font-size: 25px;
  margin-left: 2rem;
  color: lightgray;
}

.bar {
  fill: #949209;
}

@media screen and (max-width: 808px) {
  .graph-title {
    margin: 0;
  }
  .graph-cont {
    margin-bottom: 15rem;
  }
}

@media screen and (max-width: 600px) {
  .graph-cont {
    width: 110%;
    margin-bottom: 5rem;
  }
}