.footer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 0;
  border-top: 1px solid rgba(160, 150, 92, 0.256);
  font-size: 20px;
  color: white;
  position: relative;
  bottom: 0;
}

.footer-design {
  margin: 0 0 0 30px;
  font-size: 20px;
  text-align: center;
}

.contacts-cont {
  display: flex;
  justify-content: space-around;
  width: 40%;
  margin-right: 20px;
}

.contact {
  text-align: center;
  color: rgba(255, 255, 255, 0.712);
  display: flex;
  align-items: center;
}

.footer-span {
  margin-left: 10px;
  color: white;
}


@media screen and (max-width: 930px) {
  .footer-design {
    font-size: 16px;
  }
  .contacts-cont a {
    font-size: 16px;
  }
}

@media screen and (max-width: 725px) {
  .contacts-cont {
    width: 25%;
    margin-right: 30px;
  }
  .contacts-cont a {
    font-size: 20px;
  }
  .contact span {
    display: none;
  }
}

@media screen and (max-width: 580px) {
  .footer-design {
    font-size: 12px;
    /* margin-left: 20px; */
  }

}

@media screen and (max-width:420px) {
  .footer-design {
    width: 50%;
  }
  .contacts-cont {
    width: 35%;
  }
}