.project-tile-cont {
  background: linear-gradient(rgba(53, 1, 138, 0.568), rgba(0, 47, 53, 0.534));
  /* border: 3px solid rgba(64, 253, 216, 0.657); */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: center;
  text-decoration: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgb(56, 56, 56);
}

.project-tile-cont-smoll {
  background: linear-gradient(rgba(53, 1, 138, 0.568), rgba(0, 47, 53, 0.534));
  /* border: 3px solid rgba(64, 253, 216, 0.657); */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  
}

.project-image{
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: .5s ease;
  opacity: 1;
  border-radius: 10px;
  /* center image no matter what */
}

.project-image-side{
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: .5s ease;
  opacity: 1;
}

.project-overlay {
  opacity: 0;
  transition-duration: 450ms;
  position: absolute;
  height: 100%;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center ;
  align-items: center;
  background: linear-gradient(rgba(83, 48, 139, 0.568), rgba(24, 64, 70, 0.534));
  font-size: 55px !important; 
  font-weight: 900;
  border-radius: 10px;
  cursor: pointer;
}

.project-overlay:hover #tile-name {
  animation: tile-name-scale 1.5s infinite ease-in-out;
}

@keyframes tile-name-scale {
  0%{
    transform: scale(1)
  }
  50%{
    transform: scale(1.15)
  }
  100%{
    transform: scale(1.01)
  }
}

.project-overlay-side {
  opacity: 0;
  z-index: 10;
  transition-duration: 500ms;
  position: absolute;
  height: 105%;
  width: 105%;
  top: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(83, 48, 139, 0.968), rgba(24, 64, 70, 0.934));
  color: white;
  /* background-color: rgba(0, 0, 0, 0.733); */
  border: 1px solid white;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
}

#tile-name {
  cursor: pointer;
  transition: .25s;
  width: 70%;
}



#tile-name:hover {
  transform: scale(1.05);
  color: gold;
}

.project-tile-cont:hover .project-overlay, .project-tile-cont:hover .project-overlay-side{
  opacity: 1;
}

.project-tile-cont-smoll:hover .project-overlay, .project-tile-cont-smoll:hover .project-overlay-side{
  opacity: 1;
}

.project-tile-cont:hover .project-image{
  opacity: .1;
}

.project-overlay-info {
  width: 100%;
} 

 h4 {
  font-size: 30px;
  text-decoration: none;
  background: -webkit-linear-gradient(white, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 70%;
  margin: 20px 0 25px 0;
  cursor: pointer;
}

h4:hover {
  color: #949209;
  background: -webkit-linear-gradient(white, #6e6d04);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


@media screen and (max-width: 1000px) {
  h4{
    font-size: 23px;
  }
}

@media screen and (max-width: 980px) {
  .project-tile-cont-smoll {
    border-radius: 50%;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border: 3px solid goldenrod;
    scroll-snap-align: center;
    overflow: hidden;
    /* FIXED SAFARI 
       overlay overflow.
    */
    -webkit-transform: translate3d(0,0,0);
    box-shadow:  2px 4px 6px rgba(190, 170, 79, 0.337);
  }

  .projects-grid-small  .project-tile-cont-smoll  #tile-name{
    font-size: 1rem !important;  
  }

  .project-tile-cont {
    box-shadow:  2px 2px 6px rgba(190, 170, 79, 0.337);
  }

  .project-overlay {
    opacity: 1;
    border-radius: 0 0 0 0;
    height: 90%;
    justify-content: end;
    bottom: 0;
    background: linear-gradient(rgba(111, 78, 164, 0), rgb(3, 0, 15));
  }

  .project-overlay p {
    margin-block-start: 0;
    margin-block-end: 16px;
    /* display: flex; */
    
  }

  .project-overlay-side {
    opacity: .9;
    top: -3px;
    /* background: transparent; */
  }

  .project-tile-cont:hover .project-image{
    opacity: 1;
  }

 @media screen and (max-width: 808px) {
  .project-tile-cont-smoll {
     box-shadow: none;
  }
  
  .projects-grid-small  .project-tile-cont-smoll  #tile-name {
    margin-top: 10px;
    font-size: 12px !important;
    width: 90%;
  }

 }
  .project-image-side{
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .projects-grid-small  .project-tile-cont-smoll  #tile-name {
    margin-top: 15px;
  }
}