@import url('https://fonts.googleapis.com/css2?family=Arsenal&family=Fredoka+One&family=Fuzzy+Bubbles&family=Genos:wght@500&family=Krona+One&family=Merriweather+Sans:wght@300&family=Press+Start+2P&family=Roboto:wght@300&family=Tajawal:wght@200&family=Titillium+Web:wght@300&display=swap');

.welcome-section {
  height: 105vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.name-wrapper {
  width: 90%;
}

h1 {
  /* margin: 0; */
  margin-top: 0px;
  width: 90%;
  position: relative;
  top: 50%;
  left: 50%;
  /* width: 90%; */
  transform: translate(-50%, -50%);
  font-size: 110px;
  font-weight: 700;
  background: #b1b162cc -webkit-gradient(linear, left top, right top, from(#6e6d04), to(#6e6d04), color-stop(0.5, rgba(255, 255, 255, 0.952))) 0 0 no-repeat;
  -webkit-background-clip: text;
  -webkit-background-size: 150px;
  /* -webkit-text-fill-color: transparent; */
  color: rgba(255,255,255,0.1);
  animation: animate 4s infinite;
  /* position: relative; */
  z-index: 3;
}

#romanji {
  font-size: 60px;
  color: #b1b162cc;
  font-weight: 900;
}

@keyframes animate {
  0%, 10%{
    background-position: -1000px;
  }
  10%{
    background-position: top left;
  }
  98% {
    background-position: top right;
  }

  100%{
    background-position: 1200px;
  }
}

.welcome-h3 {
  font-weight: 700;
  font-size: 45px;
  background: -webkit-linear-gradient(white, #38495a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  margin: 0;
}

@media screen and (max-width: 600px) {
  .welcome-section {
    height: 100vh;
  }

  h1{
    font-size: 70px;
  }

  .welcome-h3{
    font-size: 40px;
  }

  .name-wrapper{
    width: 100%;
  }

  h1 {
    width: 100%;
    animation: animate 8s infinite;
  }
  @keyframes animate {
    0%{
      background-position: -800px;
    }
  
    100%{
      background-position: 1000px;
    }
  }
}