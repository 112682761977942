.loader-overlay{
  z-index: 1000;
  
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeAway 2.2s ease-out;
}

@keyframes fadeAway {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0
  }
}

.loader-letters {
  color: black;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
} 

.letter {
  font-size: 100px;
  font-weight: 600;
  font-family: 'Anta', 'Montserrat', sans-serif;
}

#l {
  animation: moveDown 1.2s ease;
}

#b {
  animation: bounce 1.5s linear;
}

@keyframes moveDown {
  0% { 
    transform: translateY(-600px); 
    opacity: 0;
  }
  100% { 
    transform: translateY(0px);
    opacity: 1; 
  }
}

@keyframes bounce {
  0% { 
    transform: translateY(300px); 
    opacity: 0;
  }
  50% { 
    transform: translateY(300px); 
    opacity: 0;
  }
  83% { 
    transform: translateY(0px); 
    opacity: 1
  } 
  84% { 
    transform: translateY(-5px); 
  }
  100% {
    
  }

}
