
.portfolio-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
}

.projects-cont {
  margin-top: 10px;
  min-height: 100%;
  width: 97%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: white;
  margin-bottom: 2rem;

}

@media screen and (max-width: 1150px) {
  .projects-cont { 
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }
}


.portfolio-title {
  font-size: 35px;
  margin-top: 3em;
}

.under-construction {
  font-size: 20px;
  color: rgb(255, 196, 2);
  margin: 0 0 50px 0;
}

.projects-grid-lg {
  display: grid;
  width: 80%;
  height: 100%;
  grid-template-columns: repeat(2, 35rem);
  grid-template-rows: repeat(2, 27rem);
  grid-gap: 2rem;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1150px) {
  .projects-grid-lg {
    grid-template-columns: repeat(2, 20rem);
    grid-template-rows: 1fr 1fr 1fr;
  }
  .portfolio-title {
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 800px) {
  .projects-grid-lg {
    width: 50%;
    grid-template-columns: repeat(1, 20rem);
  }

}
.projects-grid-small {
  display: grid;
  width: 15%;
  height: 100%;
  grid-template-columns: 12rem ;
  grid-gap: 10px;
  align-items: center;
  /* justify-content: center; */
  /* JUSTIFY CONTENT MAKES IT SO OVERFLOW X SCROLL DOES NOT WORK AND HIDES SOME OF THE CONTENT */
  margin: 0 0 0 0;
}

@media screen and (max-width: 1150px) {
  .projects-grid-small{
    width: 100%;
    height: 100%;
    margin-bottom: 5rem;
    grid-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
  }
}

@media screen and (max-width: 980px) {
  .projects-grid-small{
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(6, 180px) ;
    grid-gap: 20px;
    padding: 8px 0;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x proximity;
  }
  .projects-grid-small::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 808px) {
  .projects-grid-small {
    width: 100%;
    grid-template-columns: repeat(6, 125px);
  }
}

@media screen and (max-width: 600px) {
  .projects-grid-small{
    grid-template-columns: repeat(6, 110px)
  }
}

.thanks {
  width: 50%;
  font-size: 22px;
  margin: 3rem 0 6rem 0;
  font-size: 1.5rem;
  /* display: flex; */
}

@media screen and (max-width: 500px) {
  .thanks {
    font-size: 18px;
  }
}

/* Carousel */
.projects-carousel {
  height: 200px;
}

.projects-carousel {
  /* height: 80px; */
}