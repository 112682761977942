.project-cont {
  width: 75%;
  text-align: left;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1150px) {
  .project-cont {
    width: 90%;
    justify-content: center;
  }
}

.project {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 1rem;

}

.project-title {
  margin: 0 0 3rem 0;
  font-size: 28px;
}

.project-inner-cont {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 95%;
}

.project-description {
  width: 50%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /* margin-right: 5rem; */
}

.project-description p {
  font-size: 1.2rem;
  
}

a {
  color: gold;
  text-decoration: none;
  /* font-size: 1.5rem; */
}

.tools p {
  font-size: 1.5rem;
}

@media screen and (max-width: 808px) {
  .tools p {
    font-size: 1.3rem;
  }
}

.link-btns {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  min-width: 100%;
  right: 10%;
  position: relative;
  /* position: absolute; */
}

@media screen and (max-width: 1150px) {
  .link-btns {
    position: ;
    right: 0;
  }
}

.link-btns a {
  border: 1px solid gold;
  border-radius: 30px;
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  align-self: center;
  font-weight: 800;
  transition: 500ms ease;
}

.link-btns a:hover {
  border: 1px solid black;
  color: black;
  background-color: rgba(218, 190, 32, 0.863);
  font-weight: 900;
}

.project-media-cont {
  width: 45%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  /* margin-top: 4rem; */
}

.tools {
  margin: 0 0 5rem 1rem;
}




.carousel {
  margin-bottom: 5rem;
}

.imageWindow {
  height: 100%;
}

.responsive-container {
  height: 100%;
}
/* 
@media screen and (max-width: 1950px ) {
  .project-media-cont {
    width: 40%;
  }

  .carousel {
    margin-bottom: 5rem;
  }
} */


@media screen and (min-width: 1850px) {
  .project-description p {
    font-size: 25px;
  }
}

@media screen and (max-width: 1000px) {
  /* .project-cont {
    width: 70%;
  } */
  .project-description p {
    font-size: 17px;
  }
}


@media screen and (max-width: 800px) {
  .projects-cont{
    width: 90%;
    margin: 35px 30px;
  }

  .tools {
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .project-media-cont, .project-description {
    width: 97%;
  }
  
  .projects-cont{
    width: 93%;
    margin-bottom: 30px;
  }
  
  .project-description {
    padding: 0;
    margin-bottom: 3rem;
  }
  
  .carousel {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .project-title {
    text-align: center;
    margin: 0 0 2rem 0;
  }

  .project-inner-cont {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .project-media-cont, .project-description {
    width: 97%;
  }

  .project-description {
    margin-bottom: 1rem;
  }
  .project-cont{
    width: 100%;
  }
} 


