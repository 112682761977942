@import url('https://fonts.googleapis.com/css2?family=Arsenal&family=Fredoka+One&family=Fuzzy+Bubbles&family=Genos:wght@500&family=Krona+One&family=Merriweather+Sans:wght@300&family=Montserrat:wght@100&family=Press+Start+2P&family=Roboto:wght@300&family=Tajawal:wght@200&family=Titillium+Web:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Protest+Revolution&display=swap');

.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
  margin: 0;
  /* padding-top: 65px; */
  /* overflow: none; */
}

::-webkit-scrollbar {
  width: 5px;
} 

::-webkit-scrollbar-track {
  background: #6c6c5c7b;
}

::-webkit-scrollbar-thumb {
  background: rgba(235, 210, 70, 0.548);
}  

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: white; 
  scroll-behavior: smooth;
}


/* Twinkles */
@keyframes move-twinkle-back {
  from { background-position: 0 0; }
  to { background-position: -1000px 5000px; }
}

 .twinkling, .stars {
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
}

.stars {
  background: rgb(0, 0, 0) url(/src/assets/images/other-images/stars.png);
  z-index: -2;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.twinkling {
  background: transparent url(/src/assets/images/other-images/twinkling.png);
  z-index: -1;
  animation: move-twinkle-back 180s linear infinite;
  background-attachment: fixed;
}