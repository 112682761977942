.form-cont {
  min-height: 85vh;
  width: 90%;
  margin: 8rem 0 5rem 0;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  color: gold;
}

.email-title {
  font-size: 35px;
}

label {
  font-size: 20px;

  display: flex;
  justify-content: flex-start;
  margin: 0 0 5px 5px;
}

.form-control {
  margin-bottom: 50px;
  max-width: 100%;
  font-size: 15px;
}

.input-cont {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
}

.message-cont {
  height: 150px;
  
}

#btn-cont {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn {
  letter-spacing: 5px;
  width: 200px;
  height: 30;
  color: gold;
  border: 1px solid gold;
  font-size: 18px;
  font-weight: 900;
  padding: 7px 15px 7px 15px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 30px;
  transition: .2s;
  text-transform: uppercase;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

.btn:hover {
  color: black;
  background-color: gold;
}

input, textarea {
  border-radius: 10px;
  background-color: black;
  padding: 7px;
  color: white;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  border: 1px solid gray;

}



@media only screen and (max-width: 800px) { 
  .form-cont{
    margin-top: 4rem;
    padding-bottom: 0;
    margin-bottom: 1rem;
  }

  form {
    width: 85%;
  }
}