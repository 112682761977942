nav {
  height: 65px;
  width: 100%;
  position: fixed;
  top: 0;
  /* margin-top: 30px; */
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 3px;
  font-size: 20px;
  text-transform: uppercase;
  /* background-color: rgba(0, 0, 0, 0.896); */
  z-index: 1000;
  font-family: 'montserrat';
  font-weight: 1000;
}

.menu-div {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  max-width: 650px;
}

.menu-item {
  color: white;
  font-weight: 500;
  margin-right: 3rem;
  
}

.active {
  color: gold;
  border-bottom: 1px solid gold;
}

.lb-logo {
  margin-left: 50px;
  font-size: 25px;
  font-weight: 800;
  color: rgba(255, 217, 0, 0.728);
  font-family:  'Montserrat', sans-serif;
}

@media screen and (max-width: 500px) {
  .lb-logo { font-size: 30px; }
}

.hidden {
  visibility: hidden;
  display: none;
  opacity: 0;
  transition: display 0s 500ms, opacity 500ms linear;
}

.burger {
  display: none;
}

@keyframes growDown {
  0% {
      transform: scaleX(0)
  }
  80% {
      transform: scaleX(1.005)
  }
  100% {
    transform: scaleX(1)
  }
}

.menu-item:hover, .lb-logo:hover {
  color: gold;
}

@-webkit-keyframes jiggle {
  0%{
    transform: translateY(2px);
    transform: scale(1.08);
  }
  50%{
    transform: translateY(-1px);
    transform: scale(1.2);
  }
  100%{transform: translateY(0px)}
}

.ellipsis:hover #blue-gradient stop {
    color: rgb(233, 2, 2);

}

@media screen and (max-width: 808px) {
  .menu-div {
    /* display: none; */
    z-index: 100;
    flex-direction: column;
    width: 100%;
    align-items: unset;
    background-color: black;
    margin-top: -100;
    box-shadow: 0px 0px 1px;
    transition: all .3s ease;
    overflow: hidden;
    height: 0;
  }

  .menu-display {
    padding: 10px 0;
    margin-top: 23rem;
    height: 300px;
  }

  .menu-item {
    height: 2rem;
    border: none;
    margin: 1rem 0;
  }

  .lb-logo {
    margin-left: 20px;
  }

  .burger {
    display: block;
    margin-right: 10px;
  }

  @keyframes growDownMobile {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.005)
    }
    100% {
        transform: scaleY(1)
    }
  }

}

