.blurb{
  height: 100%;
  width: 70%;
  text-align: left;
  display: flex;
  flex-direction: row;
  margin: 0 0 150px 0;

}

.blurb-p {
  width: 80%;
  /* margin-top: 50px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.blurb-img {
  height: 250px;
  width: auto;
  border-radius: 5%;
  margin-left: 50px;
}

@media screen and (max-width: 900px) {
  .blurb {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .blurb-img {
    width: 300px;
    height: auto;
    margin: 0 0 50px 0;
  }
  .blurb-p {
    margin-bottom: 7rem;
    font-size: 22px;
  }
}     

@media screen and (max-width:670px) {
  .blurb{
    width: 95%;
    margin: 0;
  }
}


@media screen and (max-width: 550px) {
  .blurb-p {
    font-size: 20px;
    margin-left: 15px;
  }
  .blurb-img {
    width: 250px;
    height: auto;
  }
}
